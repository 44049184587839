import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useWindowResize from "../../utils/BreakPoints";

function Footer() {
  const device = useWindowResize();
  const { t } = useTranslation();

  if (device === "mobile") {
    return (
      <div
        style={{
          height: "80px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1rem",
        }}
      >
        {/* this is just for styling purposes its gonna be removed in the future */}
      </div>
    );
  } else {
    return (
      <div className="footer-container">
        <p>
          © {new Date().getFullYear()} {t("footer.companyName")}.{" "}
          {t("footer.rightsReserved")}
        </p>
        <p>
          <Link to="/">{t("footer.home")}</Link>
        </p>
        <p>
          <Link to="/terms-and-conditions">{t("footer.terms")}</Link>
        </p>
        <p>
          <Link to="/privacy-policy">{t("footer.privacy")}</Link>
        </p>
        <p>
          <Link to="/about-us">{t("footer.about")}</Link>
        </p>
        <p>
          <Link to="/contact">{t("footer.contactUs")}</Link>
        </p>
        <p>
          <Link to="/pricing">{t("footer.pricing")}</Link>
        </p>
        <p style={{ margin: "0 10px" }}>
          <Link to="/faq" style={{ color: "grey", textDecoration: "none" }}>
            {t("footer.faq")}
          </Link>
        </p>
      </div>
    );
  }
}

export default Footer;

//New Footer to be used in the future

/* 
import React from "react";
import { Layout, Row, Col, Input, Button, Icon } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const CustomFooter = () => {
  const { t } = useTranslation();

  return (
    <Footer style={{ backgroundColor: "#333", color: "#fff", padding: "20px 0" }}>
      <div className="footer-container">
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <h4 style={{ color: "#fff" }}>{t("footer.company")}</h4>
            <p>{t("footer.companyName")}</p>
            <p>{t("footer.address")}</p>
            <p>{t("footer.contact")}</p>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <h4 style={{ color: "#fff" }}>{t("footer.navigation")}</h4>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li><Link to="/" style={{ color: "#fff" }}>{t("footer.home")}</Link></li>
              <li><Link to="/about" style={{ color: "#fff" }}>{t("footer.about")}</Link></li>
              <li><Link to="/services" style={{ color: "#fff" }}>{t("footer.services")}</Link></li>
              <li><Link to="/contact" style={{ color: "#fff" }}>{t("footer.contact")}</Link></li>
              <li><Link to="/privacy" style={{ color: "#fff" }}>{t("footer.privacy")}</Link></li>
              <li><Link to="/terms" style={{ color: "#fff" }}>{t("footer.terms")}</Link></li>
            </ul>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <h4 style={{ color: "#fff" }}>{t("footer.followUs")}</h4>
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", marginRight: 10 }}><Icon type="facebook" /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", marginRight: 10 }}><Icon type="twitter" /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", marginRight: 10 }}><Icon type="linkedin" /></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", marginRight: 10 }}><Icon type="instagram" /></a>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <h4 style={{ color: "#fff" }}>{t("footer.newsletter")}</h4>
            <Input placeholder={t("footer.emailPlaceholder")} style={{ marginBottom: 10 }} />
            <Button type="primary">{t("footer.subscribe")}</Button>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <p>&copy; {new Date().getFullYear()} {t("footer.companyName")}. {t("footer.rightsReserved")}</p>
      </div>
    </Footer>
  );
};

export default CustomFooter; */
