import React from "react";
import { useHistory } from "react-router-dom";
import onlineShop from "../../data/img/online-shopping.svg";
import webShop from "../../data/img/web-shopping.svg";
import customizeProducts from "../../data/img/shopping.svg";
import calendar from "../../data/img/online-calendar.svg";
import { Button, Typography, Icon, Rate } from "antd";
import contactUs from "../../data/img/contact.svg";
import { useTranslation } from "react-i18next";

/**
 * @description PricingPage component is used to show the pricing page
 * @component
 * @returns {JSX.Element} - Rendered PricingPage component
 *
 * */
const PricingPage = () => {
  const { Title, Text, Paragraph } = Typography;
  const { t } = useTranslation();
  const history = useHistory();
  const IconStyle = {
    fontSize: "50px",
    color: "#1890ff",
  };

  return (
    <div className="pricing-page">
      {/* Banner Section */}
      <section className="banner-section">
        <img src={onlineShop} alt="Online Shopping" className="banner-image" />
        <div className="banner-text">
          <Title level={1}>{t("pricingPage.bannerTitle")}</Title>
          <p>{t("pricingPage.bannerText")}</p>
          <Button
            type="primary"
            size="large"
            className="cta-button"
            onClick={() => history.push("/product/upload")}
          >
            {t("pricingPage.bannerButton")}
          </Button>
        </div>
      </section>
      {/* Features Section */}
      <section className="features-section">
        <h2> {t("pricingPage.featuresTitle")} </h2>
        <div className="feature-cards">
          <div className="feature-card">
            <img src={webShop} alt="Feature 1 Icon" className="feature-icon" />
            <h3> {t("pricingPage.feature1Title")} </h3>
            <p>{t("pricingPage.feature1Text")}</p>
            <Button type="primary">{t("pricingPage.learnMoreButton")}</Button>
          </div>
          <div className="feature-card">
            <img
              src={customizeProducts}
              alt="Feature 2 Icon"
              className="feature-icon"
            />
            <h3> {t("pricingPage.feature2Title")} </h3>

            <p>{t("pricingPage.feature2Text")}</p>
            <Button type="primary">{t("pricingPage.learnMoreButton")}</Button>
          </div>
          <div className="feature-card">
            <img src={calendar} alt="Feature 3 Icon" className="feature-icon" />
            <h3> {t("pricingPage.feature3Title")}</h3>
            <p>{t("pricingPage.feature3Text")}</p>
            <Button type="primary">{t("pricingPage.learnMoreButton")}</Button>
          </div>
        </div>
      </section>
      {/* Pricing Section */}
      <section className="pricing-section">
        <h2>{t("pricingPage.pricingTitle")}</h2>

        <div className="pricing-cards">
          <div className="pricing-card">
            <Icon style={IconStyle} type="tags" />
            <h3>{t("pricingPage.listingPriceTitle")}</h3>

            <p>{t("pricingPage.listingPriceText")}</p>
          </div>
          <div className="pricing-card">
            <Icon style={IconStyle} type="percentage" />
            <h3>{t("pricingPage.commissionTitle")}</h3>

            <p>{t("pricingPage.commissionText")}</p>
          </div>
        </div>
      </section>
      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>{t("pricingPage.testimonialsTitle")}</h2>
        <div className="testimonials">
          <div className="testimonial">
            <p>{t("pricingPage.testimonial1")}</p>
            <div className="rating">
              <Rate disabled allowHalf defaultValue={4.5} />
              <p>Raja B.</p>
            </div>
          </div>
          <div className="testimonial">
            <p>{t("pricingPage.testimonial2")}</p>
            <div className="rating">
              <Rate disabled allowHalf defaultValue={5} />
              <p>Salim Bensliman</p>
            </div>
          </div>
          <div className="testimonial">
            <p>{t("pricingPage.testimonial3")}</p>
            <div className="rating">
              <Rate disabled allowHalf defaultValue={5} />
              <p>Amel Hadadi</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="contact-us-section">
        <Title level={2}>{t("pricingPage.contactUsTitle")}</Title>
        <div className="contact-details-container">
          <img src={contactUs} alt="Contact Us" className="contact-us-img" />
          <div className="contact-details">
            <Paragraph>{t("pricingPage.contactUsText")}</Paragraph>
            <Paragraph copyable={{ text: "+213657685961" }}>
              {t("pricingPage.contactUsPhone")}: +213 657 68 59 61
            </Paragraph>
            <a
              href={`https://wa.me/${"213657685961"}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary">WhatsApp</Button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingPage;
