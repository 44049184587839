import React from "react";
import { Button, Typography, message, Card, Divider } from "antd";
import {
  handleCheckout,
  currencies,
  paymentMethods,
  checkoutData,
  prices,
} from "../../utils/PaymentUtils/PaymentUtils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Image, Placeholder } from "cloudinary-react";

/**
 * @description This component represents the Commission Payment Page.
 * It displays the product information and commission details.
 * The user can initiate the commission payment process by clicking the pay button.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const CommissionPayPage = () => {
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  const location = useLocation();
  const { productInfo } = location.state;
  const language = localStorage.getItem("i18nextLng");
  const commissionAmount = Math.round(
    productInfo.price * productInfo.quantity * prices.commission
  );

  /**
   * @description This function handles the commission payment process.
   * It sends a request to the server to create a checkout session and then redirects the user to the payment page.
   * The payment page is a third-party payment gateway (chargily) where the user can complete the payment.
   *
   * @async
   * @function handleCommissionCheckout
   * @returns {void}
   */
  const handleCommissionCheckout = async () => {
    const checkoutBody = {
      amount: commissionAmount,
      currency: currencies[3].name, //DZD
      payment_method: paymentMethods[6].name,
      success_url: checkoutData.success_url,
      failure_url: checkoutData.failure_url,
      webhook_endpoint: checkoutData.webhook_endpoint,
      description: checkoutData.description.commission,
      locale: language || checkoutData.locale.ar,
      collect_shipping_address: false,
      percentage_discount: 0,
      metadata: {
        product_id: productInfo.productId,
        orderId: productInfo.orderId,
        productName: productInfo.name,
        productPrice: productInfo.price,
      },
    };

    const data = await handleCheckout(checkoutBody);
    const checkoutId = data.data.id;
    localStorage.setItem("checkoutId", checkoutId);
    const checkoutUrl = data.data.checkout_url;
    if (checkoutUrl && checkoutUrl.startsWith("http")) {
      window.location.href = checkoutUrl;
    } else {
      message.error(t("commissionPage.checkoutError"));
    }
  };

  return (
    <div className="listing-fee-container">
      <Card
        bordered={false}
        className="payment-card"
        cover={
          <Image
            cloudName="dhf7tdtdc"
            publicId={productInfo.image}
            secure="true"
            width="300"
            responsive
            crop="scale"
            fetchFormat="auto"
            loading="lazy"
          >
            <Placeholder type="blur" />
          </Image>
        }
      >
        <div className="listing-fee-price-tag">
          <Title level={4}>
            {t("commissionPage.commissionTitle")}:{" "}
            {`${commissionAmount} ${t("prodDetails.DA")}`}{" "}
          </Title>
        </div>
        <p>
          {t("commissionPage.commissionRate")}:{" "}
          {Math.round(prices.commission * 100)} %
        </p>
        <Divider />
        <div className="listing-fee-product-info">
          <Paragraph>
            <strong>{t("uploadProd.prodNme")}:</strong> {productInfo.name}
          </Paragraph>
          <Paragraph>
            <strong>{t("uploadProd.prodPrc")}:</strong> {productInfo.price}{" "}
            {t("prodDetails.DA")}
          </Paragraph>
        </div>
        <Divider />
        <div className="listing-fee-benefits">
          <Title level={4}>{t("commissionPage.benefitsTitle")}</Title>
          <ul>
            <li>{t("commissionPage.benefit1")}</li>
            <li>{t("commissionPage.benefit2")}</li>
            <li>{t("commissionPage.benefit3")}</li>
          </ul>
        </div>
        <Button block type="primary" onClick={handleCommissionCheckout}>
          {t("commissionPage.payButton")}
        </Button>
      </Card>
    </div>
  );
};

export default CommissionPayPage;
