// filepath: /home/ahlam/Documents/talentmakes/client/src/components/QRCodeGenerator.js
import React from "react";
import { QRCodeSVG } from "qrcode.react";

/**
    * @description QRCodeGenerator component is used to generate a QR code for the share link
    * @component
    * @param {Object} props - The props object
    * @param {string} props.userId - The user id
    * @param {string} props.shareId - The share id
    * @param {boolean} props.home - The home page flag
    * @param {string} props.productId - The product id
    * @returns {JSX.Element} - Rendered QRCodeGenerator component
    */
   
const QRCodeGenerator = (props) => {
  const shareUrl = props.home
    ? `https://www.talentmakes.com/?share=${props.shareId}`
    : props.product
    ? `https://www.talentmakes.com/product/${props.productId}/?share=${props.shareId}`
    : `https://www.talentmakes.com/user/${props.userId}/?share=${props.shareId}`;
  const QRCodeProps = {
    value: shareUrl,
    size: 128,
    bgColor: "#ffffff",
    fgColor: "#209cee",
    level: "L",
    renderAs: "svg",
    imageSettings: {
      src: "/favicon-32x32.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    },
  };
  return (
    <div>
      <QRCodeSVG {...QRCodeProps} />
    </div>
  );
};

export default QRCodeGenerator;
