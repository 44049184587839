import React, { useState } from "react";
import { Button, Typography, Steps, Icon, Divider } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { Step } = Steps;

const Onboarding = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userType, setUserType] = useState("");
  const { t } = useTranslation();
  //producer steps
  /**
   * @type {Array<{title: string, content: string, Icon: JSX.Element}>}
   * @description This array contains all the steps for the producer onboarding process.
   * @typedef {Array} producerSteps
   *
   */
  const producerSteps = [
    {
      title: t("onBoarding.welcomeTitle"),
      content: t("onBoarding.welcomeContent"),
      Icon: (
        <Icon
          type="heart"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.setUpYourShopTitle"),
      content: t("onBoarding.setUpYourShopContent"),
      Icon: (
        <Icon
          type="shop"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.showcaseYourTalentTitle"),
      content: t("onBoarding.showcaseYourTalentContent"),
      Icon: (
        <Icon
          type="highlight"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.scheduleYourOrdersTitle"),
      content: t("onBoarding.scheduleYourOrdersContent"),
      Icon: (
        <Icon
          type="calendar"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.getStartedTitle"),
      content: t("onBoarding.getStartedContent"),
    },
  ];

  /**
   * @type {Array<{title: string, content: string, Icon: JSX.Element}>}
   * @description This array contains all the steps for the consumer onboarding process.
   * @typedef {Array} consumerSteps
   *
   * */
  const consumerSteps = [
    {
      title: t("onBoarding.consumerWelcomeTitle"),
      content: t("onBoarding.consumerWelcomeContent"),
      Icon: (
        <Icon
          type="heart"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.discoverUniqueProductsTitle"),
      content: t("onBoarding.discoverUniqueProductsContent"),
      Icon: (
        <Icon
          type="gift"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
    {
      title: t("onBoarding.customizeYourOrderTitle"),
      content: t("onBoarding.customizeYourOrderContent"),
      Icon: (
        <Icon
          type="highlight"
          theme="twoTone"
          twoToneColor="#eb2f96"
          style={{ fontSize: "50px" }}
        />
      ),
    },
  ];

  const steps = userType === "producer" ? producerSteps : consumerSteps;
  
  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {userType === "" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
          className="onboarding-header"
        >
          <Title level={2}>{t("tourPrompt.welcomeMessage")}</Title>
          <Icon
            type="heart"
            theme="twoTone"
            twoToneColor="#eb2f96"
            style={{ fontSize: "50px" }}
          />
          <Text>{t("onBoarding.guideContent")}</Text>
          <Divider />
          <div className="user-type">
            <Button
              type={userType === "producer" ? "primary" : "default"}
              onClick={() => setUserType("producer")}
            >
              {t("onBoarding.iWantToSell")}
            </Button>
            <Divider type="vertical" />
            <Button
              type={userType === "consumer" ? "primary" : "default"}
              onClick={() => setUserType("consumer")}
            >
              {t("onBoarding.iWantToShop")}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Steps size="small" current={currentStep} direction="horizontal">
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            <Title level={4}>{steps[currentStep].title}</Title>
            {steps[currentStep].Icon}
            <Text>{steps[currentStep].content}</Text>
          </div>
          <Divider />
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                {t("onBoarding.next")}
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" onClick={onComplete}>
                {t("onBoarding.done")}
              </Button>
            )}
            {currentStep > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                {t("onBoarding.previous")}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Onboarding;
