import Axios from "axios";

/*******************************
  CHARGILY CHECKOUT FUNCTIONS
********************************/
/**
 * @description This function handles the checkout process by sending the checkout data to the server to create a checkout session and return the response from the server. The response contains the checkout session details and the checkout_url to redirect the user to the payment gateway.
 * @async handleCheckout
 * @param {Object} checkoutData
 * @param {number} checkoutData.amount
 * @param {string} checkoutData.currency
 * @param {string} checkoutData.payment_method
 * @param {string} checkoutData.success_url
 * @param {string} checkoutData.failure_url
 * @param {string} checkoutData.webhook_endpoint
 * @param {string} checkoutData.description
 * @param {string} checkoutData.locale
 * @param {boolean} checkoutData.collect_shipping_address
 * @param {Object} checkoutData.metadata
 * @returns {Promise} response - The response from the server
 * @returns {Object} response.data - The data from the server containing the checkout session details. And has the checkout_url to redirect the user to the payment gateway, to see the full response object, check the link below.
 * @see https://dev.chargily.com/pay-v2/the-quick-guide/create-a-checkout
 *
 */
export const handleCheckout = async (checkoutData) => {
  try {
    const response = await Axios.post(
      "/api/payment/create-checkout",
      checkoutData
    );

    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @description This function returns the payment method object based on the id provided.
 * @param {number} id - The id of the payment method
 * @returns {Object} paymentMethod - The payment method object
 * @returns {number} paymentMethod.id - The id of the payment method
 * @returns {string} paymentMethod.name - The name of the payment method
 * @returns {string} paymentMethod.icon - The icon of the payment method
 */
export const getPaymentMethod = (id) => {
  return paymentMethods.find((method) => method.id === id);
};

/**
 * @description This fiunction return the paymentDate and expirationDate based on the number of months provided.
 * @param {number} numberOfMonths - The number of months for the subscription or listing to expire
 * @returns {Object} dates - The dates object containing the paymentDate and expirationDate
 * @returns {Date} dates.paymentDate - The payment date
 * @returns {Date} dates.expirationDate - The expiration date
 * */
export const calculatePaymentDates = (numberOfMonths) => {
  const paymentDate = new Date();
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + numberOfMonths);
  return { paymentDate, expirationDate };
};

/********************
    CHECKOUT DATA
*********************/

/**
 * @description This object contains all the prices for the listing fee and subscription plans.
 * @typedef {Object} prices
 * @property {number} listingFee - The price for the product listing fee
 * @property {number} subscription - The price for the subscription plan
 */
export const prices = {
  listingFee: 70,
  subscription: 990,
  commission: 0.14, // 14% commission
};

/**
 * @description This array contains all the payment methods available for the checkout process.
 * @typedef {Array} paymentMethods
 * @property {number} id - The id of the payment method
 * @property {string} name - The name of the payment method
 * @property {string} icon - The icon of the payment method
 *
 */
export const paymentMethods = [
  {
    id: 1,
    name: "Visa",
    icon: "/images/visa.png",
  },
  {
    id: 2,
    name: "MasterCard",
    icon: "/images/mastercard.png",
  },
  {
    id: 3,
    name: "PayPal",
    icon: "/images/paypal.png",
  },
  {
    id: 4,
    name: "Stripe",
    icon: "/images/stripe.png",
  },
  {
    id: 5,
    name: "Apple Pay",
    icon: "/images/apple-pay.png",
  },
  {
    id: 6,
    name: "Google Pay",
    icon: "/images/google-pay.png",
  },
  {
    id: 7,
    name: "edahabia",
    icon: "/images/chargily.png",
  },
  {
    id: 8,
    name: "cib",
    icon: "/images/edahabia.png",
  },
  {
    id: 9,
    name: "chargily_app",
    icon: "/images/edahabia.png",
  },
];

/**
 * @description This array contains all the currencies available for the checkout process.
 * @typedef {Array} currencies
 * @property {number} id - The id of the currency
 * @property {string} name - The name of the currency
 * @property {string} symbol - The symbol of the currency
 *
 */
export const currencies = [
  {
    id: 1,
    name: "USD",
    symbol: "$",
  },
  {
    id: 2,
    name: "EUR",
    symbol: "€",
  },
  {
    id: 3,
    name: "GBP",
    symbol: "£",
  },
  {
    id: 4,
    name: "dzd",
    symbol: "DA",
  },
  {
    id: 5,
    name: "CAD",
    symbol: "C$",
  },
  {
    id: 6,
    name: "CHF",
    symbol: "CHF",
  },
];

/**
 * @description This array contains all the subscription plans available for the checkout process.
 * @typedef {Array} subscriptionPlans
 * @property {number} id - The id of the subscription plan
 * @property {string} name - The name of the subscription plan
 * @property {number} price - The price of the subscription plan
 * @property {Array} benefits - The benefits of the subscription plan
 *
 */
export const subscriptionPlans = [
  {
    id: 1,
    name: "Basic",
    price: 0,
    benefits: [
      "Access to all features",
      "Regular updates",
      "Priority customer support",
    ],
  },
  {
    id: 2,
    name: "Standard",
    price: 490,
    benefits: [
      "Access to all features",
      "Regular updates",
      "Priority customer support",
    ],
  },
  {
    id: 3,
    name: "Premium",
    price: 990,
    benefits: [
      "Access to all features",
      "Regular updates",
      "Priority customer support",
    ],
  },
];

/**
 * @description This object contains all the data needed for the checkout process. The data includes the success_url, failure_url, webhook_endpoint, description, locale, collect_shipping_address, and metadata.
 * @typedef {Object} checkoutData
 * @property {string} success_url - The URL to redirect the user to after a successful payment
 * @property {string} failure_url - The URL to redirect the user to after a failed payment
 * @property {string} webhook_endpoint - The URL for the webhook endpoint to receive payment events
 * @property {Object} description - The description object containing the description for the listing fee and subscription
 * @property {Object} locale - The locale object containing the language for the checkout process
 * @property {boolean} collect_shipping_address - A boolean value to determine if the shipping address should be collected
 * @property {Array} metadata - An array of metadata objects
 *
 */
export const checkoutData = {
  success_url: "/payment-gateway-redirect",
  failure_url: "/payment-failure",
  webhook_endpoint: "/api/payment/chargily-webhook",
  description: {
    listingFee: "Your product listing fee",
    subscription: "Subscription to talentmakes",
    commission: "Commission payment",
  },
  locale: { en: "en", fr: "fr", ar: "ar" },
  collect_shipping_address: false,
  metadata: [{}],
};

/**
 * @description This object contains all the payment types available for the checkout process.
 * @typedef {Object} paymentTypes
 * @property {string} listingFee - The payment type for the product listing fee
 * @property {string} listingFeeRenewal - The payment type for the product listing fee renewal
 * @property {string} subscription - The payment type for the subscription
 * @property {string} subscriptionRenewal - The payment type for the subscription renewal
 *
 */
export const paymentTypes = {
  listingFee: "listingFee",
  listingFeeRenewal: "listingFeeRenewal",
  subscription: "subscription",
  subscriptionRenewal: "subscriptionRenewal",
};
