import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @description PaymentFailure component is used to show the payment failure message
 * @component
 * @returns {JSX.Element} - Rendered PaymentFailure component
 * */
const PaymentFailure = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleBackHome = () => {
    localStorage.removeItem("productInfo");
    history.push("/");
  };

  const retryUpload = () => {
    const paymentType = localStorage.getItem("paymentType");
    const productInfo = JSON.parse(localStorage.getItem("productInfo"));
    if(paymentType === "commission") history.push({pathname: "/commission-pay", state: {productInfo}}); 
    else 
    history.push("/listing-fee");
  };

  return (
    <Result
      status="error"
      title={t("paymentFailure.title")}
      subTitle={t("paymentFailure.subTitle")}
      extra={[
        <Button
          type="primary"
          key="home"
          onClick={retryUpload}
        >
          {t("paymentSuccess.retryUploadButton")}
        </Button>,
        <Button key="list" onClick={handleBackHome}>
          {t("paymentFailure.backHomeButton")}
        </Button>,
      ]}
    />
  );
};

export default PaymentFailure;
