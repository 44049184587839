import React from "react";
import { Button, Typography, message, Card, Divider } from "antd";
import {
  handleCheckout,
  prices,
  currencies,
  paymentMethods,
  checkoutData,
} from "../../utils/PaymentUtils/PaymentUtils";
import { useTranslation } from "react-i18next";

/**
 * @description This component represents the Listing Fee Payment Page.
 * It displays the product information, listing fee details, and benefits of paying the listing fee.
 * The user can initiate the listing fee checkout process by clicking the pay button.
 *
 * @component
 * @example
 * return (
 *   <ListingFeePayPage />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @function handleListingFeeCheckout
 * @description This function handles the listing fee checkout process.
 * It sends a request to the server to create a checkout session using the handleCheckout function,
 * which is an async function that calls the API endpoint on the server and gets the response containing the checkout_url.
 * If the checkout_url is valid, it redirects the user to the payment page.
 * Otherwise, it displays an error message.
 *
 * @async
 * @returns {void}
 * @see {@link https://dev.chargily.com/pay-v2/the-quick-guide/create-a-checkout}
 */
const ListingFeePayPage = () => {
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  //find the current language of the user
  const language = localStorage.getItem("i18nextLng");
  const productInfo = JSON.parse(localStorage.getItem("productInfo"));

  /**
   * @description This function handles the listing fee checkout process.
   * It sends a request to the server to create a checkout session and then redirects the user to the payment page.
   * The payment page is a third-party payment gateway (chargily) where the user can complete the payment.
   *
   * @async
   * @function handleListingFeeCheckout
   * @param {Object} checkoutBody - The checkout data object containing the payment details.
   * @returns {void}
   * @see {@link https://dev.chargily.com/pay-v2/the-quick-guide/create-a-checkout}
   */
  const handleListingFeeCheckout = async () => {
    const checkoutBody = {
      amount: prices.listingFee,
      currency: currencies[3].name, //DZD
      payment_method: paymentMethods[6].name,
      success_url: checkoutData.success_url,
      failure_url: checkoutData.failure_url,
      webhook_endpoint: checkoutData.webhook_endpoint,
      description: checkoutData.description.listingFee,
      locale: language || checkoutData.locale.ar,
      collect_shipping_address: false,
      percentage_discount: 0,
      metadata: {
        product_id: productInfo._id,
        productName: productInfo.title,
        productPrice: productInfo.price,
        prodcutCategory: productInfo.category,
      },
    };

    const data = await handleCheckout(checkoutBody);
    const checkoutId = data.data.id;
    localStorage.setItem("checkoutId", checkoutId);
    const checkoutUrl = data.data.checkout_url;
    if (checkoutUrl && checkoutUrl.startsWith("http")) {
      window.location.href = checkoutUrl;
    } else {
      message.error(t("listingFeePage.checkoutError"));
    }
  };

  return (
    <div className="listing-fee-container">
      <Card
        bordered={false}
        className="payment-card"
        cover={
          <img
            src={productInfo.images[0]}
            alt={productInfo.title}
            className="product-image"
          />
        }
      >
        <div className="listing-fee-price-tag">
          <Title level={4}>
            {t("listingFeePage.listingFeeTitle")}:{" "}
            {`${prices.listingFee} ${t("prodDetails.DA")}`}{" "}
          </Title>
          <p>/{t("listingFeePage.perThreeMonths")}</p>
        </div>
        <Divider />
        <div className="listing-fee-product-info">
          <Paragraph>
            <strong>{t("uploadProd.prodNme")}:</strong> {productInfo.title}
          </Paragraph>
          <Paragraph>
            <strong>{t("uploadProd.prodPrc")}:</strong> {productInfo.price}{" "}
            {t("prodDetails.DA")}
          </Paragraph>
          <Paragraph>
            <strong>{t("listingFeePage.category")}:</strong>{" "}
            {productInfo.category}
          </Paragraph>
        </div>
        <Divider />
        <div className="listing-fee-benefits">
          <Title level={4}>{t("listingFeePage.benefitsTitle")}</Title>
          <ul>
            <li>{t("listingFeePage.benefit1")}</li>
            <li>{t("listingFeePage.benefit2")}</li>
            <li>{t("listingFeePage.benefit3")}</li>
          </ul>
        </div>
        <Button block type="primary" onClick={handleListingFeeCheckout}>
          {t("listingFeePage.payButton")}
        </Button>
      </Card>
    </div>
  );
};

export default ListingFeePayPage;
