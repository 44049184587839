import { useEffect, useState } from 'react';

const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });

      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        registration.addEventListener('updatefound', () => {
          const installingWorker = registration.installing;
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
              setWaitingWorker(installingWorker);
            }
          });
        });
      });
    }
  }, []);

  const updateServiceWorker = () => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
  };

  return { waitingWorker, updateServiceWorker };
};

export default useServiceWorker;