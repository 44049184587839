import React, { useState, useEffect } from "react";
import { Button, Result, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";

/**
 * @description CommissionPaySuccess component is used to show the commission payment success message
 * @component
 * @returns {JSX.Element} - Rendered CommissionPaySuccess component
 */
const CommissionPaySuccess = () => {
  const { t } = useTranslation();
  const [uploadFailed, setUploadFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const productInfo = JSON.parse(localStorage.getItem("productInfo"));
  const checkoutId = localStorage.getItem("checkoutId");
  const history = useHistory();
  const paymentDate = new Date().toISOString(); // Current date
  const abortController = new AbortController();
  const signal = abortController.signal;

  /**
   * @description handleCommissionPaymentConfirmationAsync function is used to confirm the commission payment
   * it calculates the payment and expiration dates and sends a post request to the server
   * to update the producer information
   *
   * @async
   * @fires calculatePaymentDates
   * @fires axios.post
   * @returns {undefined}
   */
  const handleCommissionPaymentConfirmationAsync = async () => {
    if (!productInfo) {
      message.error(t("commissionPaySuccess.productInfoMissing"));
      return;
    }

    try {
      // First API call to update delivered orders
      const deliveredOrderResponse = await axios.post(
        "/api/deliveredOrder/updateCommissionPaymentStatus",
        {
          orderId: productInfo.orderId,
          paymentDate,
          checkoutId,
          paidStatus: true,
        },
        { signal }
      );

      if (deliveredOrderResponse.status === 200) {
        // Second API call to update user information
        const userResponse = await axios.post(
          "/api/users/updateCommissionPaymentStatus",
          {
            producerId: productInfo.producerId,
            orderId: productInfo.orderId,
            paymentDate,
            checkoutId,
            paidStatus: true,
          },
          { signal }
        );

        if (userResponse.status === 200) {
          message.success(t("commissionPaySuccess.paymentStatusUpdated"));
          localStorage.removeItem("productInfo");
          localStorage.removeItem("checkoutId");
          localStorage.setItem("paymentType", "commission");
        } else {
          setUploadFailed(true);
          message.error(t("commissionPaySuccess.paymentStatusNotUpdated"));
        }
      } else {
        setUploadFailed(true);
        message.error(t("commissionPaySuccess.paymentStatusNotUpdated"));
      }
    } catch (error) {
      setUploadFailed(true);
      message.error(t("commissionPaySuccess.paymentStatusNotUpdated"));
    }
  };

  /**
   * @description useEffect hook to handle the commission payment confirmation
   * @fires handleCommissionPaymentConfirmationAsync
   * @fires localStorage.removeItem
   *
   * @returns {undefined}
   */
  useEffect(() => {
    let isMounted = true;

    handleCommissionPaymentConfirmationAsync();

    return () => {
      isMounted = false;
      abortController.abort();
      localStorage.removeItem("producerInfo");
    };
  }, []);

  return (
    <Result
      status="success"
      title={t("commissionPaySuccess.title")}
      subTitle={t("commissionPaySuccess.subTitle")}
      extra={[
        <Button
          type="primary"
          key="home"
          onClick={() => history.push("/deliveryDates")}
        >
          {t("commissionPaySuccess.deliveryDates")}
        </Button>,
        <Button key="list" onClick={() => history.push("/")}>
          {t("commissionPaySuccess.homeButton")}
        </Button>,
        uploadFailed && (
          <Button
            loading={loading}
            type="danger"
            key="retry"
            onClick={handleCommissionPaymentConfirmationAsync}
          >
            {t("commissionPaySuccess.retryGetDeliveryAdress")}
          </Button>
        ),
      ]}
    />
  );
};

export default CommissionPaySuccess;
