import React, { useState, useEffect } from "react";
import { Button, Result, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { calculatePaymentDates } from "../../utils/PaymentUtils/PaymentUtils";

/**
 * @description PaymentSuccess component is used to show the payment success message
 * @component
 * @returns {JSX.Element} - Rendered PaymentSuccess component
 */
const PaymentSuccess = () => {
  const { t } = useTranslation();
  const [uploadFailed, setUploadFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const productInfo = JSON.parse(localStorage.getItem("productInfo"));
  const checkoutId = localStorage.getItem("checkoutId");
  const history = useHistory();
  const { paymentDate, expirationDate } = calculatePaymentDates(3); // 3 months

  /**
   * @description useEffect hook to handle the payment confirmation
   * @fires handlePaymentConfirmationAsync
   * @fires localStorage.removeItem
   *
   *
   * @returns {undefined}
   */
  useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const signal = abortController.signal;

    /**
     * @description handlePaymentConfirmationAsync function is used to confirm the payment
     * it calculates the payment and expiration dates and sends a post request to the server
     * to upload the product information
     *
     * @async
     * @fires calculatePaymentDates
     * @fires axios.post
     * @returns {undefined}
     */
    const handlePaymentConfirmationAsync = async () => {
      if (!productInfo) {
        message.error(t("paymentSuccess.productInfoMissing"));
        return;
      }

      try {
        const response = await axios.post(
          "/api/product/uplaodProduct",
          { ...productInfo, paymentDate, expirationDate, checkoutId },
          { signal }
        );

        if (isMounted) {
          if (response.data.success) {
            message.success(t("paymentSuccess.uploadSuccess"));
            localStorage.removeItem("productInfo");
          } else {
            setUploadFailed(true);
            message.error(t("paymentSuccess.uploadFailed"));
          }
        }
      } catch (error) {
        if (isMounted) {
          setUploadFailed(true);
          message.error(t("paymentSuccess.uploadFailed"));
        }
      }
    };

    handlePaymentConfirmationAsync();

    return () => {
      isMounted = false;
      abortController.abort();
      localStorage.removeItem("productInfo");
    };
  }, []); // Ensure the dependency array is empty

  /**
   * @description handleRetryUpload function is used to retry the upload of the product information
   * when the upload fails
   * @fires axios.post
   * @returns {undefined}
   * */
  const handleRetryUpload = async () => {
    if (!productInfo) {
      message.error(t("paymentSuccess.productInfoMissing"));
      return;
    }
    try {
      const response = await axios.post("/api/product/uplaodProduct", {
        ...productInfo,
        checkoutId,
        paymentDate,
        expirationDate,
      });

      if (response.data.success) {
        message.success(t("paymentSuccess.uploadSuccess"));
        localStorage.removeItem("productInfo");
      } else {
        setUploadFailed(true);
        message.error(t("paymentSuccess.uploadFailed"));
      }
    } catch (error) {
      setUploadFailed(true);
      message.error(t("paymentSuccess.uploadFailed"));
    }
  };

  return (
    <Result
      status="success"
      title={t("paymentSuccess.title")}
      subTitle={t("paymentSuccess.subTitle")}
      extra={[
        <Button
          type="primary"
          key="home"
          onClick={() => history.push("/profile")}
        >
          {t("paymentSuccess.dashboardButton")}
        </Button>,
        <Button key="list" onClick={() => history.push("/product/upload")}>
          {t("paymentSuccess.uploadButton")}
        </Button>,
        uploadFailed && (
          <Button
            loading={loading}
            type="danger"
            key="retry"
            onClick={handleRetryUpload}
          >
            {t("paymentSuccess.retryUploadButton")}
          </Button>
        ),
      ]}
    />
  );
};

export default PaymentSuccess;
