import React, { useState, useEffect } from "react";
import { Button, Result, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { calculatePaymentDates } from "../../utils/PaymentUtils/PaymentUtils";

/**
 * @description This component represents the Listing Fee Renewal Success Page.
 * It displays a success message after the user has successfully renewed the listing fee.
 * The user can navigate to the dashboard or upload another product.
 * If the upload fails, the user can retry uploading the product.
 * The product info is stored in the local storage.
 * The component sends a request to the server to update the product dates.
 * The payment date and expiration date are calculated using the calculatePaymentDates function.
 * If the request is successful, it displays a success message.
 * Otherwise, it displays an error message.
 * @component
 * @example
 * return (
 *  <ListingFeeRenewalSuccess />
 * )
 * @returns {JSX.Element} The ListingFeeRenewalSuccess component.
 */

const ListingFeeRenewalSuccess = () => {
  const { t } = useTranslation();
  const [uploadFailed, setUploadFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const productInfo = JSON.parse(localStorage.getItem("productInfo"));
  const checkoutId = localStorage.getItem("checkoutId");
  const history = useHistory();
  const { paymentDate, expirationDate } = calculatePaymentDates(3); // 3 months

  /**
   * @description This hook runs when the component mounts.
   * It sends a request to the server to update the product dates.
   * The payment date and expiration date are calculated using the calculatePaymentDates function.
   * If the request is successful, it displays a success message.
   * Otherwise, it displays an error message.
   * @callback useEffect
   * @param {Function} handlePaymentConfirmationAsync - A function that sends a request to the server to update the product dates.
   * @param {Array} - An empty array of dependencies that triggers the effect
   * @returns {void}
   * @see {@link https://reactjs.org/docs/hooks-effect.html}
   * @see {@link https://dev.chargily.com/pay-v2/the-quick-guide/create-a-checkout}
   *
   * @async
   * @function handlePaymentConfirmationAsync
   * @returns {void}
   * @throws {Error} If the product info is not found or the upload fails, it throws an error.
   * */
  useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const signal = abortController.signal;

    const handlePaymentConfirmationAsync = async () => {
      try {
        if (!productInfo) {
          message.error(t("paymentSuccess.productInfoMissing"));
          return;
        }
        const response = await axios.put(
          `/api/product/updateProductDates/${productInfo._id}`,
          {
            paymentDate,
            expirationDate,
            checkoutId,
          },
          { signal }
        );
        if (isMounted) {
          if (response.data.success) {
            message.success(t("paymentSuccess.renewalSuccess"));
            localStorage.removeItem("productInfo");
          } else {
            setUploadFailed(true);
            message.error(t("paymentSuccess.renewalFailed"));
          }
        }
      } catch (error) {
        if (isMounted) {
          setUploadFailed(true);
          message.error(t("paymentSuccess.renewalFailed"));
        }

        throw error;
      }
    };

    handlePaymentConfirmationAsync();

    return () => {
      isMounted = false;
      abortController.abort();
      localStorage.removeItem("productInfo");
    };
  }, []);

  const handleRetryUpload = () => {
    setLoading(true);
    axios
      .put(`/api/product/updateProductDates/${productInfo._id}`, {
        paymentDate,
        expirationDate,
        checkoutId,
      })
      .then((response) => {
        if (response.data.success) {
          message.success(t("paymentSuccess.renewalSuccess"));
          localStorage.removeItem("productInfo");
        } else {
          setUploadFailed(true);
          message.error(t("paymentSuccess.renewalFailed"));
        }
      })
      .catch((error) => {
        setUploadFailed(true);
        message.error(t("paymentSuccess.renewalFailed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Result
      status="success"
      title={t("paymentSuccess.title")}
      subTitle={t("paymentSuccess.subTitle")}
      extra={[
        <Button
          type="primary"
          key="home"
          onClick={() => history.push("/profile")}
        >
          {t("paymentSuccess.dashboardButton")}
        </Button>,
        <Button key="list" onClick={() => history.push("/product/upload")}>
          {t("paymentSuccess.uploadButton")}
        </Button>,

        uploadFailed && (
          <Button key="retry" onClick={handleRetryUpload}>
            {t("paymentSuccess.retryRenewalButton")}
          </Button>
        ),
      ]}
    />
  );
};

export default ListingFeeRenewalSuccess;
