import React, { useState, useEffect } from "react";
import Tour from "reactour";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import Onboarding from "./OnBoarding";

/**
 * @description A component that displays a tour guide for the user
 * @category {Component}
 * @param {Object[]} steps - An array of steps to be displayed in the tour guide
 * @type {Array<{selector: string, content: string, position: string}>}
 * @param {string} steps[].selector - The selector of the element to be highlighted in the tour guide
 * @param {string} steps[].content - The content to be displayed in the tour guide
 * @param {string} steps[].position - The position of the tour guide
 * @returns {JSX.Element} - A modal that contains the tour guide.
 *
 */
const TourGuide = ({ steps }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);

  /**
   * @description A hook that runs when the component mounts to check if the user is authenticated and has not seen the tour guide before
   * @callback useEffect
   * @param {Function} - A function that sets the show modal state to true if the user is authenticated and has not seen the tour guide before. Updates the local storage to indicate that the user has seen the tour guide.
   * @param {Array} - An array of dependencies that triggers the effect
   * @returns {undefined}
   *
   */
  useEffect(() => {
    if (user.userData && user.userData.isAuth) {
      const tourGuide = localStorage.getItem("tourGuide");
      if (!tourGuide) {
        setShowModal(true);
        localStorage.setItem("tourGuide", "true");
      }
    }
  }, [user.userData]);

  const startTour = () => {
    setIsTourOpen(true);
    setShowModal(false);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  return (
    <div>
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={closeTour} />
      <Modal
        centered
        visible={showModal}
        onOk={() => startTour()}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Onboarding onComplete={startTour} />
        </div>
      </Modal>
    </div>
  );
};

export default TourGuide;
