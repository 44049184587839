import React from "react";
import { useTranslation } from "react-i18next";
import PreLaunch from "../PreLaunchView/PreLaunch";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="about-us-container">
      <div className="about-us-section">
        <h1>{t("about.title")}</h1>
        <p>{t("about.description")}</p>
      </div>
      <div className="about-us-section">
        <h1> {t("about.missionTitle")}</h1>
        <p>{t("about.mission")}</p>
      </div>
      <div className="about-us-section">
        <h1> {t("about.visionTitle")}</h1>
        <p>{t("about.vision")}</p>
      </div>
    </div>
  );
};

export default AboutUs;
